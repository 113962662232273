import React from 'react';
import {Link} from 'react-router-dom';
import banner from '../images/banner.png';
import mobilebanner from '../images/mobile_banner.png';
const Banner = ()=>{
    return(
        <>
        <div className='banner'>
            <Link to='/contact'>
       <img src={banner} alt="banner" className="webbanner" width="100%" />
       <img src={mobilebanner} alt="banner" className="mobilebanner" width="100%" />
       </Link>

       </div>
        </>
    )
}

export default Banner;