import React from 'react';
import logo from '../images/logo2.png';
import {Link} from 'react-router-dom';
const Footer = ()=>{
    return(
        <>
        <div className='footer'>
            <div className='footer_about'>
            <img src={logo} alt="inspiritwebs"/>
                <p>Team of software engineer's who are now freelancer's to serve the business aspirants by designing and developing the website's, also to implement digital marketing for their business @ pocket friendly price.</p>
            </div>
           
            <div className='footer_contact'>
                <h3>Contact Details</h3>
                <p><span>Ph</span> +971 525911295</p>
                <p><span>Email</span> queries@inspiritwebs.com</p>
                <p><Link to="/contact">Send your message to us</Link></p>
                <div className='footer_policies'>
                <Link to="/termsandconditions">Terms and Conditions</Link>
               
            </div>
            </div>
         
        </div>
        <div className='poweredby'>
              
              <ul>
                  <li>All rights reserved @ inspiritwebs.com.</li>
                  <li>Designed & Developed with <i class="fa-solid fa-heart"></i> <img src={logo} alt="footer logo"/></li>
              </ul>
          </div>
        </>
    )
}

export default Footer;