import React from 'react';
import emailjs from 'emailjs-com';
const Mailer = ()=>{
    function sentEmail(e){
        e.preventDefault();
        emailjs.sendForm('service_zvvhiei', 'template_37gcdkn', e.target, 'w1xuumppWbqZxBqWo').then(res=>{
            console.log(res);
        }).catch(err=> console.log(err));
       
    }
 
    // useEffect(()=>{
    //     const submitbtn = document.querySelector('.button-submit');
    //     const name = document.querySelector('#name');
    //     const email = document.querySelector('#email');
    //     const mobile = document.querySelector('#mobile');
    //     const message = document.querySelector('#message');
    //     submitbtn.addEventListener("click", (e)=>{
    //         e.preventDefault();
    //         name.value="";
    //         email.value="";
    //         mobile.value="";
    //         message.value="";
    //             })
    // })
       

  

    return(

        <>
        <div className='mailer'>
        
            <form onSubmit={sentEmail}>
                <label>Name</label>
                <input type="text" name="name" id='name'/>

                <label>Email</label>
                <input type="email" name="user-email" id='email'/>

                <label>Phone Number</label>
                <input type="number" name="mobile" id='mobile'/>

                <label for="cars">Choose a plan</label>
                <select name="plans" id="plans">
                <option value="basic" name="basic" id="basic">Basic Plan</option>
                <option value="silver">Silver Plan</option>
                <option value="gold">Gold Plan</option>
                <option value="platinum">Platinum Plan</option>
                <option value="discuss">Need to decide</option>
                </select>

                <label>Message</label>
                <textarea name="message" rows="4" id='message'/>

                <input className='button-submit' type="submit" value="SEND"/>
            </form>
           
        </div>
        <h3 className="or">OR</h3>
        <div className='contact-details'>
           <div className='contact_phone contact-wrap'><i class="fa-solid fa-phone-volume"></i><p>+971 525911295</p></div>
           <div className='contact_email contact-wrap'><i class="fa-solid fa-envelope"></i><p>info@inspiritwebs.com</p></div>
       </div>
        </>
    )
}

export default Mailer;