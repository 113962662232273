import React from 'react';
// import { Link } from 'react-router-dom';

const Keypoint = ()=>{
    return(
        <>
        <div className='keypoints'>
       <div className='point'>
           <h2>Why you need website</h2>
        
           <p>In this digital world, a website plays a major role in a businesses, irrespective of what the size and type of a business. Website helps in increasing the brand presence via the Internet breaking the geographical boundaries. It is proved that the outspread of the business or a company is far more with the website with less expenses than any form of marketing with huge expenses.</p>
           {/* <Link to='/'>Know More</Link> */}
       </div>
       <div className='point'>
       <h2>Designed with Love</h2>
           <p>Who doesn't want to look good! your website is one amoung. Website design is the one which increases the visibility of your business and makes public engage on your website to know more about your business and products. We know the importance of the website designing and so, we design it with love.</p>
           {/* <Link to='/'>Know More</Link> */}
       </div>
       <div className='point'>
       <h2>Developed with Care</h2>
           <p>A successful website does 03 things:
It reaches the right audience based on their need. Guides them and engages them on your website to know more about your company/business and to explore the services and products that you offer. It gives an option to contact you through the website. We know the importance of a business and a website and so, we Develop it with care.</p>
{/* <Link to='/'>Know More</Link> */}
       </div>
       </div>
        </>
    )
}

export default Keypoint;