import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import india from '../images/india.svg';
import uae from '../images/uae.svg';
import usa from '../images/usa.svg';
import england from '../images/england.svg';
const Packages = ()=>{
    useEffect(()=>{
        // const p = document.querySelector('.basic_price p');
const actualprice = document.querySelector('.basic_price .actualprice');
const offerprice = document.querySelector('.basic_price .offerprice');

const s_actualprice = document.querySelector('.silver_price .s_actualprice');
const s_offerprice = document.querySelector('.silver_price .s_offerprice');

const g_actualprice = document.querySelector('.gold_price .g_actualprice');
const g_offerprice = document.querySelector('.gold_price .g_offerprice');

const ind = document.querySelector('.india');
ind.addEventListener('click',()=>{actualprice.innerText = "Actual price Rs. 3000/-"; offerprice.innerText = "Offer price Rs. 1600/-"});

const uae = document.querySelector('.uae');
uae.addEventListener('click',()=>{actualprice.innerText = "Actual price AED 300/-"; offerprice.innerText = "Offer price AED 210/-"});

const usa = document.querySelector('.usa');
usa.addEventListener('click',()=>{actualprice.innerText = "Actual price $ 200"; offerprice.innerText = "Offer price $ 120"});

const england = document.querySelector('.england');
england.addEventListener('click',()=>{actualprice.innerText = "Actual price P 150"; offerprice.innerText = "Offer price P 80"});


// const s_ind = document.querySelector('.silver_price .india');
// s_ind.addEventListener('click',()=>{s_actualprice.innerText = "Actual price Rs. /-"; s_offerprice.innerText = "Offer price Rs. /-"});


const s_uae = document.querySelector('.silver_price .uae');
s_uae.addEventListener('click',()=>{s_actualprice.innerText = "Actual price AED 880/-"; s_offerprice.innerText = "Offer price AED 580/-"});

const s_usa = document.querySelector('.silver_price .usa');
s_usa.addEventListener('click',()=>{s_actualprice.innerText = "Actual price $ 550"; s_offerprice.innerText = "Offer price $ 380"});

const s_england = document.querySelector('.silver_price .england');
s_england.addEventListener('click',()=>{s_actualprice.innerText = "Actual price P 420"; s_offerprice.innerText = "Offer price P 290"});

const g_ind = document.querySelector('.gold_price .india');
g_ind.addEventListener('click',()=>{g_actualprice.innerText = "Actual price --"; g_offerprice.innerText = "Offer price Rs. gold/-"});


const g_uae = document.querySelector('.gold_price .uae');
g_uae.addEventListener('click',()=>{g_actualprice.innerText = "Actual price AED 1700/-"; g_offerprice.innerText = "Offer price AED 1300"});

const g_usa = document.querySelector('.gold_price .usa');
g_usa.addEventListener('click',()=>{g_actualprice.innerText = "Actual price $ 1020"; g_offerprice.innerText = "Offer price $ 760"});

const g_england = document.querySelector('.gold_price .england');
g_england.addEventListener('click',()=>{g_actualprice.innerText = "Actual price P 900"; g_offerprice.innerText = "Offer price P 650"});


    });
    return(
        <>
        <div className='packages_main'>
       <h2>Choose a plan for your Website</h2>
        <div className='package_wrap'>
       
            <div className='package'>
                <div className='package_head'>
                    <p className="packagelogo"><i class="fa-solid fa-house-chimney"></i></p>
                    <p>Basic Plan</p>

                </div>
                <div className='package_price basic_price'>
                    <p className='actualprice'> Actual price AED 210</p>
                    <p className='offerprice'>Offer price 120 AED</p>
                    <div className="countries_currency">
                    {/* <div className='india'><img src={india} alt="india"/></div> */}
                    <div className='uae'><img src={uae} alt="uae"/></div>
                    <div className='usa'><img src={usa} alt="usa"/></div>
                    <div className='england'><img src={england} alt="england"/></div>
                    </div>
                </div>
                <div className='package_content'>
                <ul>    
                    <li><i class="fa-solid fa-square-check"></i> 1 Static Website </li>
                    <li><i class="fa-solid fa-square-check"></i> Website Development</li>
                    <li><i class="fa-solid fa-square-check"></i> Only Desktop Version</li>
                    <li><i class="fa-solid fa-square-check"></i> Basic Banners</li>
                    <li><i class="fa-solid fa-square-xmark redcross"></i> No website design</li>
                    <li><i class="fa-solid fa-square-xmark redcross"></i> No Logo design</li>
                    <li><i class="fa-solid fa-square-xmark redcross"></i> No Responsiveness</li>
                    <li><i class="fa-solid fa-square-xmark redcross"></i> No support</li>
                </ul>
                </div>
                <div className='getstarted_btn'>
                <Link to="/contact" className='getstarted'>GET STARTED</Link>
                </div>
            </div>

            <div className='package'>
               
                <div className='package_head'>
                    <p className="packagelogo"><i class="fa-solid fa-star"></i></p>
                    <p>Silver Plan<span> - BEST SELLER</span></p>
                    
                </div>
                <div className='package_price silver_price'>
                <p className='s_actualprice'> Actual price AED 360</p>
                    <p className='s_offerprice'>Offer price 210 AED</p>
                    <div className="countries_currency">
                    {/* <div className='india'><img src={india} alt="india"/></div> */}
                    <div className='uae'><img src={uae} alt="uae"/></div>
                    <div className='usa'><img src={usa} alt="usa"/></div>
                    <div className='england'><img src={england} alt="england"/></div>
                    </div>
                </div>
                <div className='package_content'>
                <ul>    
                    <li><i class="fa-solid fa-square-check"></i> 1 static website </li>
                    <li><i class="fa-solid fa-square-check"></i> Website Development</li>
                    <li><i class="fa-solid fa-square-check"></i> Website Designing</li>
                    <li><i class="fa-solid fa-square-check"></i> Mobile, Tablet, laptop and large screen responsiveness</li>
                    <li><i class="fa-solid fa-square-check"></i> Basic Banners</li>
                    <li><i class="fa-solid fa-square-check"></i> 02 Months Support</li>
                    <li><i class="fa-solid fa-square-xmark redcross"></i> No Logo Design</li>
                    
                </ul>
                </div>
                <div className='getstarted_btn'>
                <Link to="/contact" className='getstarted'>GET STARTED</Link>
                </div>
            </div>

            <div className='package'>
                <div className='package_head'>
                    <p className="packagelogo"><i class="fa-solid fa-crown"></i></p>
                    <p>Gold Plan</p>

                </div>
                <div className='package_price gold_price'>
                <p className='g_actualprice'> Actual price AED 490</p>
                    <p className='g_offerprice'>Offer price 380 AED</p>
                    <div className="countries_currency">
                    <div className='india' style={{display:"none"}}><img src={india} alt="india"/></div>
                    <div className='uae'><img src={uae} alt="uae"/></div>
                    <div className='usa'><img src={usa} alt="usa"/></div>
                    <div className='england'><img src={england} alt="england"/></div>
                    </div>
                </div>
                <div className='package_content'>
                <ul>    
                <li><i class="fa-solid fa-square-check"></i> 1 Semi Dynamic Website </li>
                    <li><i class="fa-solid fa-square-check"></i> Website Development</li>
                    <li><i class="fa-solid fa-square-check"></i> Website Designing</li>
                    <li><i class="fa-solid fa-square-check"></i> Mobile, Tablet, laptop and large screen responsiveness</li>
                    <li><i class="fa-solid fa-square-check"></i> Pro Banners</li>
                    <li><i class="fa-solid fa-square-check"></i> Basic Logo Design</li>
                    <li><i class="fa-solid fa-square-check"></i> 04 Months Support</li>
                   
                </ul>
                </div>
                <div className='getstarted_btn'>
                <Link to="/contact" className='getstarted'>GET STARTED</Link>
                </div>
            </div>

            <div className='package'>
                <div className='package_head'>
                    <p className="packagelogo"> <i class="fa-solid fa-rings-wedding"></i> <i class="fa-solid fa-gem"></i></p>
                    <p>Platinum Plan</p>

                </div>
                <div className='package_price'>
                    <p></p>
                    <p>Let's discuss</p>
                </div>
                <div className='package_content'>
                <ul>    
                <li><i class="fa-solid fa-square-check"></i> 1 Dynamic website </li>
                    <li><i class="fa-solid fa-square-check"></i> Website Development</li>
                    <li><i class="fa-solid fa-square-check"></i> Website Designing</li>
                    <li><i class="fa-solid fa-square-check"></i> Mobile, Tablet, laptop and large screen responsiveness</li>
                    <li><i class="fa-solid fa-square-check"></i> Pro Banners</li>
                    <li><i class="fa-solid fa-square-check"></i> Pro Logo Design</li>
                    <li><i class="fa-solid fa-square-check"></i> 1 year/ Lifetime Support</li>
                </ul>
                </div>
                <div className='getstarted_btn'>
                <Link to="/contact" className='getstarted'>GET STARTED</Link>
                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default Packages;