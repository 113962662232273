import React from 'react';
import Banner from './Banner';
import Keypoint from './Keypoints';

import Services from './Services';
import Tabs from './Tabs';
import Templates from './Templates';
import About from './About';
// import Responsivebanner from './Responsivebanner';
// import Modal from './Modal';
import Packages from './Packages';
import Digitalmarketing from './Digitalmarketing';
import Growing from './Growing';
// import Termsandconditions from './Termsandconditions';

const Home = ()=>{
    
   
    return(
        <>
         {/* <Modal/> */}
        <Banner/>
<div className='container'>
<Keypoint/>

</div>
<About/>
<Packages/>
<Digitalmarketing/>
<Tabs/>
<Templates/>
<div className='container'>
<Services/>
{/* <Termsandconditions/> */}
</div>
<Growing/>
{/* <Responsivebanner/> */}

        </>
    )
}

export default Home;