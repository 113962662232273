import React from 'react';
import whytext from '../images/whyus-text.png';

const Whyuspage =()=>{
    return(
        <>
         <div className='contentpage'>
         <img src={whytext} alt="about us"/>
         <p>We are not a company, we are experienced freelancers. We’ve been there in IT industry since more than 10 years and with our experience, commitment and dedication we will make your website customer-focused, adding value to your business.</p>
         <ul>
         <h4>We're not a company, so.. </h4>
    
    <li> - we do not include taxes for your payment. You're here to reduce unnecessary charges.</li>
    <li> - We do not charge the amount for the entire team so, the cost estimation for the service is very less. </li>
    <li> - We don't cost the earth. Our quotations are very reasonable comparatively. We do not charge the amount on hourly basis. We only charge the minimum price for the entire project.</li>
    
    <h4>We're experienced freelancer's</h4>
    
    <li> - We don't take months to develop a project. We deliver the project in less than 10 - 15 days, and the product is your's. </li>
    
    <h4>Best part</h4>

    <li> - Incase if you are not satisfied with the final output of the project, the amount paid to us as advance is 100% refunded back.</li>
    </ul>
     </div>
        </>
    )
}

export default Whyuspage;