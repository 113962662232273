import React from 'react';

const Error = ()=>{
    return(
        <>
        <h3>404 - Page not found.</h3>
        </>
    )
}

export default Error;