import React from 'react';
import terms from '../images/terms-text.svg';
const Termsandconditions = ()=>{
    return(
        <>
        <div className='contaner'>
           <div className='contentpage'>
           <img src={terms} alt="terms"/>
           <div className='service_content'>
                    <h3>BILLING & PAYMENTS</h3>
                   <ol>
                       <li>If you are willing to own a website from “inspiritwebs”, 40% of total amount is to be paid in advance as a confirmation to get started with the website development . After the website is developed, once you are satisfied with the website the will make the website live. Before making the website live the remaining 60% of the amount need to be paid. </li>
                       <li>Any website templates which are available on inspiritwebs will cost you just Rs 5000/-  including desktop, mobile and tablet screens (responsiveness).</li>
                       <li>The amount of Rs.5000/- includes website designing, website development and adding content. Website domain name and hosting has to be purchased by the website owner itself. </li>
                       <li>Website logo creation will be charged extra amount of Rs.2000.</li>
                       <li>Template includes the home page and 4 to 5 content pages like about us, services, why we, contact us.</li>
                       <li>Apart from the template specification, ant additional page and/or functionality will be charged extra based on the requirement.</li>
                       <li>After the development of a website, due to some reasons if you do not like the website, you need to inform us before we make the website live. The 100% amount is refunded.</li>
                       <li>Once the website is approved and once it is live, the amount is not refunded.</li>
                       </ol>
                       <h3>AWARENESS</h3>
                       <ol>
                           <li>Be aware of fake calls in the name of “inspiritwebs”</li>
                           <li>We communicated through the official phone number (9121463826) and email (sainath@inspiritwebs.com / info@inspiritwebs.com) only. Any calls or emails apart from the mentioned phone number or email’s are to be considered as fraud. </li>
                           <li>We are not responsible if you respond to the calls or emails which does not belong to “inspiritwebs”. If you receive any such calls or emails, please do inform us ASAP.</li>
                           
                       </ol>
                </div>
           </div>
            </div>
        </>
    )
}

export default Termsandconditions;