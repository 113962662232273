import React from 'react';
import servicetext from '../images/services-text.png';
import Services from './Services';
const Servicespage =()=>{
    return(
        <>
       
         <div className='contentpage servicepage'>
         <img src={servicetext} alt="about us"/>
         <p>From strategy to execution, our combination of innovative thinking, logical designing sophisticated development and ongoing analysis ensure we're delivering value to help you grow. You can <span></span>Communicate directly with the audience <span></span>Get more customers <span></span>Turn brand into business <span></span>Enhance connectivity <span></span>Prove your ability <span></span>Reduce promotion expenditure. Below are our services for your Business.
         </p>
         <Services/>

     </div>
        </>
    )
}

export default Servicespage;