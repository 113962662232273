import React from 'react';
import webpage from '../images/webpage.png';
const Tabs = ()=>{
    return(
        <>
        <div className='tabs'>
            <div className='tabs_left_image'>
                <div className='screen_banner'><img src={webpage} alt="webpage"/></div>
            </div>
            <div className='tabs_right_content'>
            <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Responsive
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       Responsive Web design helps public to access your website on their finger tips. In simple words, with a Responsive Website one can access the website from <span>Mobile, Tablet, Laptop and Desktop</span>.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       Creative Website's
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Who doesn't want to look good? so, your website. Website is the one which promotes your company, products and services. Better the website, better the user friendly, more the trafic to your website.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Trending
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Loading content...
      </div>
    </div>
  </div>
</div>
            </div>
        </div>
        </>
    )
}

export default Tabs;