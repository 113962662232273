import React from 'react';
import comingsoon from '../images/comingsoon.png';
const Templates = ()=>{
    return(
        <>
        <div className='template'>
            <div className='template_content'>
                <h2>Stunning Portfolio</h2>
                <p>Templates are the websites which we have Designed, Developed and Delivered to our clients based on their business reqirement and business standards. </p>
                <p className='price'>Choose a website design for your business, we develop it for you <span>Starting at just AED 210 only</span></p>
            </div>
            <div className='templates_portfolio'>
                <div className='temp'><img src={comingsoon} alt="coming  soon"/></div>
                <div className='temp'><img src={comingsoon} alt="coming  soon"/></div>
                <div className='temp'><img src={comingsoon} alt="coming  soon"/></div>
                <div className='temp'><img src={comingsoon} alt="coming  soon"/></div>
                <div className='temp'><img src={comingsoon} alt="coming  soon"/></div>
        
               
            </div>
        </div>
        </>
    )
}
export default Templates;