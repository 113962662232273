import React from 'react';
import designing from '../images/designing.png';
import development from '../images/development.png';
import logoicon from '../images/logo_designing.png';
import bannericon from '../images/banner-icon.png';
import matketing from '../images/marketing.png';
import rewamp from '../images/rewamp.png';

const Services = ()=>{
    return(
        <>
        <div className='services' id="services">
            <div className='service'>
                <div className='service_icon'>
                  <img src={designing} alt="designing"/>
                </div>
                <div className='service_content'>
                    <h3>Web Designing</h3>
                    <p>Increasing visibility of your business is one of the key factors that necessitate having a beautiful & user friendly website design. A good website design arouses the curiosity of the visitors to dive in further irrespective of your business whether it is IT, restaurant, education, hobbies, or any such business,inspiritwebs is here for all your website needs.</p>
                </div>
            </div>
            <div className='service'>
                <div className='service_icon'>
                <img src={development} alt="designing"/>
                </div>
                <div className='service_content'>
                    <h3>Web Development</h3>
                    <p>Website development is the work involved in creating or developing a website. Business without a website is something like a ship lost in the water, Let us take you into a deeper experience, make a moment a lasting conveyable memory. Let us help build your tribe. we’re here as a freelancer to develop a website for your business or company.</p>
                </div>
            </div>
            <div className='service'>
                <div className='service_icon'>
                <img src={logoicon} alt="designing"/>
                </div>
                <div className='service_content'>
                    <h3>Logo Designing</h3>
                    <p>Logo is nothing but a brand. Logo is a strategy designed and implemented by companies to help people to easily identify their company/products. It is one of the ways to highlight and identify your company/business in a competitive world. Therefore, a logo is considered as one of the main graphic elements for your company that allow people to quickly identify.</p>
                </div>
            </div>
            <div className='service'>
                <div className='service_icon'>
                <img src={bannericon} alt="designing"/>
                </div>
                <div className='service_content'>
                    <h3>Banners Designing</h3>
                    <p>Website banners plays a key role on your website. Banners not only beautifies the website but also are used to showcase and to draw the attention of people to a specific product or service on your website. Banners are eye-catching enough to attract attention of the public and to engage them with your business information on the website.</p>
                </div>
            </div>
            <div className='service'>
                <div className='service_icon'>
                <img src={matketing} alt="designing"/>
                </div>
                <div className='service_content'>
                    <h3>Digital Marketing</h3>
                    <p>People are now relaying on digital media to search for a product or services they need. Digital marketing is an advertisement of your business/company/products/services which are delivered through digital media channels such as google search engines, websites, social media, email, and mobile apps. Using these digital marketing online media channels, digital marketing is the method followed by the companies to endorse their business, products, services, brands and more.</p>
                </div>
            </div>
            <div className='service'>
                <div className='service_icon'>
                <img src={rewamp} alt="designing"/>
                </div>
                <div className='service_content'>
                    <h3>Rewamp</h3>
                    <p>Is your website old? Is your website design outdated and not happy with your current website design? Customers complaining about the design & functionality? Visitors not willing to stay on your website? Yes, it happens because, outdated and old design website will limit the business and conversation with the people. Inspiritwebs is here to modify your website design to the latest trends so your business will be on track again.</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default Services;