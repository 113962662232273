import React, { useEffect } from 'react';
import logo from '../images/logo2.png';
import linkedin from '../images/linkedin.png';
import twitter from '../images/twitter.png';
import facebook from '../images/facebook.png';
import instagram from '../images/instagram.png';
import youtube from '../images/youtube.png';
import login from '../images/login.png';
import hamburger from '../images/hamburger.png';
import {Link} from 'react-router-dom';

const Navbar = ()=>{
   useEffect(()=>{
       let hamburger = document.querySelector('.hamburger');
       let mobilehide = document.querySelector('.mobile_hide');
       let mobilehides = document.querySelector('.mobile_hides');
     
       hamburger.addEventListener('click', ()=>{
        //    mobilehide.style.display="none";
        mobilehide.classList.toggle("mobilehide");
        mobilehides.classList.toggle("mobilehides");
       })
   });
    return(
        <>
        <div className='header'>
        <div className='logo'>
           <Link to="/"> <img src={logo} alt="inspiritwebs"/></Link><span className='hamburger'><img src={hamburger} alt="hamburger"/></span></div>
       
        <div className='menu mobile_hide'>
            <ul>
                <li><Link to='/'>Home</Link></li>
                {/* <li><Link to='/aboutus'>About Us</Link></li> */}
                <li><Link to='/services'>Services</Link></li>
                <li><Link to='/whyus'>Why Us</Link></li>
                <li><Link to='/contact'>Contact Us</Link></li>
            </ul>
        </div>  
        <div className='follow mobile_hides'>
            <ul>
                <li>Follow us</li>
                <li><a href="https://www.linkedin.com/in/inspirit-webs-219640234" target="_blank"><img src={linkedin} alt="linkdein"/></a></li>
                <li><a href="https://www.twitter.com/inspirit_webs" target="_blank"><img src={twitter} alt="linkdein"/></a></li>
                <li><a href="https://www.facebook.com/inspiritwebsFreelancing" target="_blank"><img src={facebook} alt="linkdein"/></a></li>
                <li><a href="#" target="_blank"><img src={instagram} alt="linkdein"/></a></li>
                <li><a href="#" target="_blank"><img src={youtube} alt="youtube"/></a></li>
                <li><a href="/" ><img className='login' src={login} alt="login"/></a></li>
            </ul>
        </div>
      
        
        </div>
       
        </>
    )
}

export default Navbar;