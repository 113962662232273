import React from 'react';
import {Link} from 'react-router-dom';
import digitalmarketingimg from '../images/digitalmarketing.png';
import digitaltraining from '../images/digitalmarketing-training.png';

const Digitalmarketing = ()=>{
    return(
        <>
        <div className='dm'>
            <div className='dm_wrap'>
                <div className='dm_service'>
                  <div className="dmservice_icon"><img src={digitalmarketingimg} alt="dm"/></div><div className="dmservice_content"><h3>Digital Marketing For Your Business <Link to="/contact">Talk to us</Link></h3>
                  <p>Digital Marketing plays a <b>KEY ROLE</b> for your business. It helps your company/business to stand out in the market by grasping the customers, serving them the products/services you have and adding the lead generations. Also, makes users/customers to visit you social media profiles &amp; more. Importantly, it also helps in ranking your website on the top in the google search results.</p></div>
                </div>
                <div className='dm_training'>
                <div className='dm_service'>
                  <div className="dmservice_icon"><img src={digitaltraining} alt="dm"/></div><div className="dmservice_content dmtraining"><h3>Learn Digital Marketing, manage your own business (or) be a FREELANCER <Link to="/contact">Join Now <span class="tooltiptext">Slot's occupied. Visit again to book a slot </span></Link> </h3><p>Digital marketing for your company/business will let you communicate directly with the customers who see your content, notably through website comments, reviews, social media posts &amp; more. It also ranks your website on the top in the google search. You can do this all by yourself for your business.</p>
                  <p>Interested in self employement? Learn now and work as a freelancer from your place.</p></div>
                </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Digitalmarketing;