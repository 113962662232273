
import './App.css';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Error from './Components/Error';
import AboutPage from './Components/AboutPage';
import Servicespage from './Components/Servicespage';
import Whyuspage from './Components/Whyuspage';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Termsandconditions from './Components/Termsandconditions';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
function App() {
  return (
    <>
{/*    
<Navbar/>
<Home/> */}
<Router>
<Navbar/>
  <Routes>
<Route path="/" element={<Home/>}/>
<Route path="/aboutus" element={<Whyuspage/>}/>
<Route path="/services" element={<Servicespage/>}/>
<Route path="/whyus" element={<Whyuspage/>}/>
<Route path="/contact" element={<Contact/>}/>
<Route path="/termsandconditions" element={<Termsandconditions/>}/>
<Route path="*" element={<Error/>}/>
  </Routes>
  <Footer/>
</Router>
   </>
  );
}
export default App;
