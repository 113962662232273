import React from 'react';
// import aboutbanner from '../images/about-banner.png';
import growing from '../images/wearegrowing2.png';
import {Link} from 'react-router-dom';
const Growing = ()=>{
    return(
        <>
          <div className='growing'>
              <div className='growing_left'>
                <h3>We Are Young & Growing...</h3>
                {/* <h4>-Partner with us, let's grow together-</h4> */}
                {/* <p>We are not a company, we are not an agency. We are a team of experienced software engineers who came up with a concept of freelancing to deliver the website at reasonable price for the Business aspirants and for those who require one. We strongly believe that we're the best choice for your business because...  </p> */}
                <Link to='/contact'>Talk To Us</Link>
              </div>
              <div className='growing_right'>
{/* <img src={growing} alt="about banner"/> */}
              </div>
          </div>
        </>
    )
}

export default Growing;