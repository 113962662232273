import React from 'react';
// import aboutbanner from '../images/about-banner.png';
import aboutbanner from '../images/akshat.png';

import {Link} from 'react-router-dom';
const About = ()=>{
    return(
        <>
          <div className='about'>
              <div className='about_left'>
                <h3>Why inspirit<span>webs..</span></h3>
                <p>We are not a company, we are not an agency. We are a team of experienced software engineers who came up with a concept of freelancing to deliver the website at reasonable price for the Business aspirants and for those who require one. We strongly believe that we're the best choice for your business because...  </p>
                <Link to='/aboutus'>Read More</Link>
              </div>
              <div className='about_right'>
<img src={aboutbanner} alt="about banner"/>
              </div>
          </div>
        </>
    )
}

export default About;