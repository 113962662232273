import React,{ useEffect }  from 'react';
import {Link} from 'react-router-dom';
import contacttext from '../images/contact-text.png';
import Mailer from '../Components/Mailer';

const Contact = ()=>{
    useEffect(()=>{
        let sub = document.querySelector(".button-submit");
        let mod = document.querySelector(".modal");
        sub.addEventListener('click',()=>{
            mod.style.display="block";
        })
    })
    return(
        <>
       
      <div className='contentpage contact_page'>
         <img src={contacttext} alt="about us"/>
         <p>Need a Website? inspirit<span>webs</span> is here to help.</p>
         <h6><span>BRAND.</span> IT COULD BE JUST THE THING YOUR BUSINESS NEED. TALK TO US NOW</h6>
    
     </div>
    
     <div className="contactform">
     <div className="form">
     <Mailer/>
     </div>
     </div>

    
 <div id="myModal" class="modal">


  <div class="modal-content">
   
  <p>Thank you! Submitted successfully. We will get back to you within 24 hours.</p>
   <Link to="/" className="btn btn-primary">Close</Link>

  </div>

</div>
        </>
    )
}

export default Contact;